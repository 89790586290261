import {patchRecord, postRecord, putRecord} from "@/services/api";
import Vue from "vue";

export async function updatePasswordByResetToken(token, password, password_confirmation, options = {}) {
    return putRecord("/api/v3/auth/password.json", {
            reset_password_token: token,
            password: await Vue.prototype.$sha256(password), // do not send cleartext pw
            password_confirmation: await Vue.prototype.$sha256(password_confirmation) // do not send cleartext pw
        },
        options
    )
}

export function sendDeleteAccountInstructions(email, options = {}) {
    options.toast = {useResponseMessage: true}
    return postRecord("/public/users/send_delete_account_instructions.json", { email: email }, options)
}

export function deleteAccount(delete_account_token, options = {}) {
    return patchRecord("/public/users/delete_account.json", { delete_account_token: delete_account_token }, options)
}